import type { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: Date; }
};

export type ApactaVendor = Entity & {
  created: Scalars['Date']['output'];
  cvr?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isCustom: Scalars['Boolean']['output'];
  modified: Scalars['Date']['output'];
  name: Scalars['String']['output'];
};

export enum Companies_Vendor_List_Sort_Fields {
  Id = 'ID',
  VendorName = 'VENDOR_NAME'
}

export type City = Entity & {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CompaniesVendor = Entity & {
  companyId?: Maybe<Scalars['ID']['output']>;
  created?: Maybe<Scalars['Date']['output']>;
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Date']['output']>;
  modifiedById?: Maybe<Scalars['ID']['output']>;
  receiveAutomaticPriceFiles?: Maybe<Scalars['Boolean']['output']>;
  receiveCopyInvoice?: Maybe<Scalars['Boolean']['output']>;
  receiveInvoice?: Maybe<Scalars['Boolean']['output']>;
  receiveInvoiceMails?: Maybe<Scalars['Boolean']['output']>;
  useCompanyProducts?: Maybe<Scalars['Boolean']['output']>;
  usePriceFiles?: Maybe<Scalars['Boolean']['output']>;
  vendor: ApactaVendor;
  vendorAccountReference?: Maybe<Scalars['String']['output']>;
  vendorDepartmentId?: Maybe<Scalars['ID']['output']>;
  vendorEmail?: Maybe<Scalars['String']['output']>;
  vendorId: Scalars['ID']['output'];
  /** This is the company's name for the vendor (the associated 'Vendor' should be the official name from cvr.dk) */
  vendorName?: Maybe<Scalars['String']['output']>;
  vendorPhone?: Maybe<Scalars['String']['output']>;
  vendorPhoneCountrycode?: Maybe<Scalars['String']['output']>;
};

export type CompaniesVendorConnection = {
  edges: Array<CompaniesVendorEdge>;
  pageInfo: PageInfo;
};

export type CompaniesVendorEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: CompaniesVendor;
};

export type CompaniesVendorListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Companies_Vendor_List_Sort_Fields>;
  withExpensesWithinDateInterval?: InputMaybe<DateInterval>;
};

export type Contact = Entity & {
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<City>;
  cityId: Scalars['ID']['output'];
  cityName?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['ID']['output']>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  country?: Maybe<Country>;
  countryId: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  createdById: Scalars['ID']['output'];
  cvr?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erpDaysOfCredit?: Maybe<Scalars['Int']['output']>;
  erpId?: Maybe<Scalars['String']['output']>;
  erpPaymentTermId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modified: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneCountrycode?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ContactConnection = {
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
};

export type ContactEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: Contact;
};

export type ContactListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPerson = Entity & {
  contactId: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  createdById: Scalars['ID']['output'];
  deleted?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Country = Entity & {
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CreateProcurementAnalysisInput = {
  companiesVendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateInterval?: InputMaybe<DateInterval>;
};

export type DateInterval = {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
};

export type Entity = {
  id: Scalars['ID']['output'];
};

export enum Form_List_Sort_Fields {
  FormDate = 'formDate',
  Id = 'id'
}

export type Form = Entity & {
  created: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  projectId: Scalars['ID']['output'];
};

export type FormConnection = {
  edges: Array<FormEdge>;
  pageInfo: PageInfo;
};

export type FormEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: Form;
};

export type FormsListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  formDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Form_List_Sort_Fields>;
};

export type Invoice = Entity & {
  combineProductLines: Scalars['Boolean']['output'];
  combineWorkingTimeLines: Scalars['Boolean']['output'];
  companyId: Scalars['ID']['output'];
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']['output']>;
  contributionMargin: Scalars['Float']['output'];
  contributionMarginPerHour: Scalars['Float']['output'];
  contributionRate: Scalars['Float']['output'];
  costPriceTotal: Scalars['Float']['output'];
  created: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  createdById: Scalars['ID']['output'];
  currencyId?: Maybe<Scalars['ID']['output']>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  daysOfCredit?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  discountPercent: Scalars['Float']['output'];
  erpId?: Maybe<Scalars['String']['output']>;
  erpPaymentTermId?: Maybe<Scalars['String']['output']>;
  euCustomer: Scalars['Boolean']['output'];
  grossPayment: Scalars['Float']['output'];
  grossSalesPriceTotal: Scalars['Float']['output'];
  groupByForms: Scalars['Boolean']['output'];
  hasProjectPdfAttached: Scalars['Boolean']['output'];
  hourUsageAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  includeInvoicedForms: Scalars['Boolean']['output'];
  includingVat?: Maybe<Scalars['Boolean']['output']>;
  integrationId?: Maybe<Scalars['ID']['output']>;
  integrationImageUrl?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['Int']['output']>;
  isBooked: Scalars['Boolean']['output'];
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  isFinalInvoice: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  isManuallyInvoiced: Scalars['Boolean']['output'];
  isOffer: Scalars['Boolean']['output'];
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  issuedDate?: Maybe<Scalars['Date']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  modified: Scalars['Date']['output'];
  netPayment: Scalars['Float']['output'];
  netSalesPriceTotal: Scalars['Float']['output'];
  offerNumber?: Maybe<Scalars['Int']['output']>;
  paidDate?: Maybe<Scalars['String']['output']>;
  paymentDueDate?: Maybe<Scalars['Date']['output']>;
  paymentTermId?: Maybe<Scalars['ID']['output']>;
  pdfUrl: Scalars['String']['output'];
  prettyDates?: Maybe<Scalars['String']['output']>;
  productUsageAmount?: Maybe<Scalars['Float']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']['output']>;
  projectOverviewAttached: Scalars['Boolean']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['ID']['output']>;
  showEmployeeName: Scalars['Boolean']['output'];
  showPaymentTerm: Scalars['Boolean']['output'];
  showPrices: Scalars['Boolean']['output'];
  showProductImages: Scalars['Boolean']['output'];
  showProductsProductBundle: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalCostPrice: Scalars['Float']['output'];
  totalDiscountPercent: Scalars['Float']['output'];
  vatPercent?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  _undefined?: Maybe<Scalars['Boolean']['output']>;
  login: Scalars['String']['output'];
  procurementAnalysisCreate: ProcurementAnalysis;
  procurementAnalysisDelete: ProcurementAnalysis;
  procurementAnalysisRestartProcessing: ProcurementAnalysis;
  procurementAnalysisRestartProcessingSync: ProcurementAnalysis;
  projectCreate: Project;
  projectDelete?: Maybe<Scalars['Boolean']['output']>;
  projectUpdate: Project;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationProcurementAnalysisCreateArgs = {
  input: CreateProcurementAnalysisInput;
};


export type MutationProcurementAnalysisDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProcurementAnalysisRestartProcessingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProcurementAnalysisRestartProcessingSyncArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProjectCreateArgs = {
  params: ProjectCreateDto;
};


export type MutationProjectDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProjectUpdateArgs = {
  id: Scalars['ID']['input'];
  params: ProjectUpdateDto;
};

export type NotImplemented = {
  id: Scalars['ID']['output'];
};

export enum Pagination_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Procurement_Analysis_Line_List_Sort_Fields {
  CountBought = 'COUNT_BOUGHT',
  CountOfExpenses = 'COUNT_OF_EXPENSES',
  Name = 'NAME',
  OptimizationPotential = 'OPTIMIZATION_POTENTIAL',
  TotalAmountPaid = 'TOTAL_AMOUNT_PAID'
}

export enum Procurement_Analysis_List_Sort_Fields {
  Created = 'CREATED',
  Id = 'ID'
}

export enum Procurement_Analysis_Status {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing'
}

export enum Project_List_Sort_Fields {
  Created = 'CREATED',
  Id = 'ID',
  Name = 'NAME',
  ProjectNumber = 'PROJECT_NUMBER'
}

export type PageInfo = {
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
};

export type PaginationEdge = {
  cursor: Scalars['String']['output'];
  node: Entity;
};

export type ProcurementAnalysis = Entity & {
  companiesVendors?: Maybe<Array<CompaniesVendor>>;
  companyId: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  dateEnd: Scalars['Date']['output'];
  dateStart: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  logs?: Maybe<Scalars['String']['output']>;
  modified: Scalars['Date']['output'];
  modifiedById?: Maybe<Scalars['ID']['output']>;
  procurementAnalysisLines?: Maybe<Array<ProcurementAnalysisLine>>;
  progress: Scalars['Int']['output'];
  status: Procurement_Analysis_Status;
};


export type ProcurementAnalysisProcurementAnalysisLinesArgs = {
  params?: InputMaybe<ProcurementAnalysisLineListParams>;
};

export type ProcurementAnalysisConnection = {
  edges: Array<ProcurementAnalysisEdge>;
  pageInfo: PageInfo;
};

export type ProcurementAnalysisEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: ProcurementAnalysis;
};

export type ProcurementAnalysisLine = Entity & {
  averageMarketPrice?: Maybe<Scalars['Float']['output']>;
  companiesVendor?: Maybe<CompaniesVendor>;
  companiesVendorId?: Maybe<Scalars['ID']['output']>;
  countBought: Scalars['Float']['output'];
  countOfExpenses: Scalars['Int']['output'];
  created: Scalars['Date']['output'];
  diffBetweenAverageAndLastPrice: Scalars['Float']['output'];
  expenseLineKeyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastPricePaid: Scalars['Float']['output'];
  minimumMarketPrice?: Maybe<Scalars['Float']['output']>;
  modified: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  optimizationPotential: Scalars['Float']['output'];
  procurementAnalysisId: Scalars['ID']['output'];
  productImageUrls?: Maybe<ResizedImageUrls>;
  totalAmountPaid: Scalars['Float']['output'];
  vendor?: Maybe<ApactaVendor>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  vendorProductId?: Maybe<Scalars['ID']['output']>;
};

export type ProcurementAnalysisLineConnection = {
  edges: Array<ProcurementAnalysisLineEdge>;
  pageInfo: PageInfo;
};

export type ProcurementAnalysisLineEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: ProcurementAnalysisLine;
};

export type ProcurementAnalysisLineListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  optimizationPotentialGte?: InputMaybe<Scalars['Float']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  showOnlyWithPotential?: InputMaybe<Scalars['Boolean']['input']>;
  showOnlyWithoutPotential?: InputMaybe<Scalars['Boolean']['input']>;
  sortBy?: InputMaybe<Procurement_Analysis_Line_List_Sort_Fields>;
};

export type ProcurementAnalysisListParams = {
  companiesVendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateInterval?: InputMaybe<DateInterval>;
  direction?: InputMaybe<Pagination_Direction>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Procurement_Analysis_List_Sort_Fields>;
};

export type ProcurementKpiCards = {
  averageTotalPricePerExpense: Scalars['Float']['output'];
  countOfProductsBought: Scalars['Int']['output'];
  priceUpdatesCount: Scalars['Int']['output'];
  totalMoneySpent: Scalars['Float']['output'];
};

export type ProcurementKpiCardsParams = {
  companiesVendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateInterval?: InputMaybe<DateInterval>;
};

export type Project = Entity & {
  city?: Maybe<City>;
  cityName?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['ID']['output'];
  /** Is heavy calculation - consider using only this when selecting single project */
  completionPercentage?: Maybe<Scalars['Float']['output']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']['output']>;
  contactPersonId?: Maybe<Scalars['ID']['output']>;
  created: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstActivityDate?: Maybe<Scalars['Date']['output']>;
  forms: FormConnection;
  fullName: Scalars['String']['output'];
  hasFinalInvoice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceDiscountPercent?: Maybe<Scalars['Float']['output']>;
  isFixedPrice: Scalars['Boolean']['output'];
  isOffer: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modified: Scalars['Date']['output'];
  modifiedBy?: Maybe<User>;
  modifiedById?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  notInvoicedAmount: Scalars['Float']['output'];
  offerId?: Maybe<Scalars['ID']['output']>;
  productsTotalCostPrice: Scalars['Float']['output'];
  projectFiles: ProjectFileConnection;
  projectImageUrl?: Maybe<Scalars['String']['output']>;
  projectNumber?: Maybe<Scalars['Int']['output']>;
  projectStatus: ProjectStatus;
  projectStatusId: Scalars['ID']['output'];
  project_type?: Maybe<ProjectTypeEnum>;
  streetName?: Maybe<Scalars['String']['output']>;
  totalInvoicedAmount?: Maybe<Scalars['Float']['output']>;
  totalSalesPrice: Scalars['Float']['output'];
  workingHoursTotalCostPrice: Scalars['Float']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type ProjectFormsArgs = {
  params?: InputMaybe<FormsListParams>;
};


export type ProjectProjectFilesArgs = {
  params?: InputMaybe<ProjectFilesListParams>;
};

export type ProjectConnection = {
  edges: Array<ProjectEdge>;
  pageInfo: PageInfo;
};

export type ProjectCreateDto = {
  name: Scalars['String']['input'];
};

export type ProjectEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: Project;
};

export type ProjectFile = Entity & {
  created: Scalars['Date']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  fileOriginalName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
};

export type ProjectFileConnection = {
  edges: Array<ProjectFileEdge>;
  pageInfo: PageInfo;
};

export type ProjectFileEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: ProjectFile;
};

export type ProjectFilesListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields>;
};

export type ProjectListParams = {
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectNumber?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<Array<ProjectStatusEnum>>;
  q?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Project_List_Sort_Fields>;
};

export type ProjectStatus = Entity & {
  created: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['Date']['output'];
  name?: Maybe<Scalars['String']['output']>;
  projectStatusType: ProjectStatusType;
  projectStatusTypeId: Scalars['ID']['output'];
};

export enum ProjectStatusEnum {
  Closed = 'closed',
  Open = 'open',
  ReadyForBilling = 'ready_for_billing'
}

export type ProjectStatusType = Entity & {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum ProjectTypeEnum {
  Estimate = 'estimate',
  FixedPrice = 'fixed_price',
  Hourly = 'hourly',
  Offer = 'offer'
}

export type ProjectUpdateDto = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  _undefined?: Maybe<Scalars['Boolean']['output']>;
  companiesVendor: CompaniesVendor;
  companiesVendors: CompaniesVendorConnection;
  contact: Contact;
  contacts: ContactConnection;
  invoice: Invoice;
  me: User;
  procurementAnalyses: ProcurementAnalysisConnection;
  procurementAnalysis: ProcurementAnalysis;
  procurementKpiCards: ProcurementKpiCards;
  project: Project;
  projectFile: ProjectFile;
  projectFiles: ProjectFileConnection;
  projects: ProjectConnection;
  vendorProductPriceFile: VendorProductPriceFile;
  vendorProductPriceFileKpiCardData: VendorProductPriceFileKpiCardData;
  vendorProductPriceFiles: VendorProductPriceFileConnection;
};


export type QueryCompaniesVendorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompaniesVendorsArgs = {
  params?: InputMaybe<CompaniesVendorListParams>;
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactsArgs = {
  params?: InputMaybe<ContactListParams>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcurementAnalysesArgs = {
  params?: InputMaybe<ProcurementAnalysisListParams>;
};


export type QueryProcurementAnalysisArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcurementKpiCardsArgs = {
  params?: InputMaybe<ProcurementKpiCardsParams>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectFilesArgs = {
  params?: InputMaybe<ProjectFilesListParams>;
};


export type QueryProjectsArgs = {
  params?: InputMaybe<ProjectListParams>;
};


export type QueryVendorProductPriceFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVendorProductPriceFileKpiCardDataArgs = {
  params: VendorProductPriceFileKpiCardDataParams;
};


export type QueryVendorProductPriceFilesArgs = {
  params?: InputMaybe<VendorProductPriceFileListParams>;
};

export type ResizedImageUrls = {
  large: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  original: Scalars['String']['output'];
  small: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export type User = {
  companyId: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['Date']['output'];
};

export enum Vendor_Product_Price_File_List_Sort_Fields {
  Created = 'CREATED',
  Id = 'ID'
}

export type VendorProductPriceFile = Entity & {
  companiesVendor: CompaniesVendor;
  companiesVendorId: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  createdById?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Date']['output']>;
  dir?: Maybe<Scalars['String']['output']>;
  downloadUrl: Scalars['String']['output'];
  file: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['Date']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
  /** 5 Past price files from that same vendor */
  pastPriceFiles: VendorProductPriceFileConnection;
  progress?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  vendorProductsCount: Scalars['Int']['output'];
  vendorProductsCountTotal: Scalars['Int']['output'];
};

export type VendorProductPriceFileConnection = {
  edges: Array<VendorProductPriceFileEdge>;
  pageInfo: PageInfo;
};

export type VendorProductPriceFileEdge = PaginationEdge & {
  cursor: Scalars['String']['output'];
  node: VendorProductPriceFile;
};

export type VendorProductPriceFileKpiCardData = {
  consequence: Scalars['Float']['output'];
  priceDecreasesCount: Scalars['Int']['output'];
  priceDevelopment: Scalars['Float']['output'];
  priceIncreasesCount: Scalars['Int']['output'];
};

export type VendorProductPriceFileKpiCardDataParams = {
  dateInterval?: InputMaybe<DateInterval>;
  vendorProductPriceFileId: Scalars['ID']['input'];
};

export type VendorProductPriceFileListParams = {
  dateInterval?: InputMaybe<DateInterval>;
  direction?: InputMaybe<Pagination_Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyNonCustomVendors?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Vendor_Product_Price_File_List_Sort_Fields>;
  vendorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ProjectSelectionListQueryVariables = Exact<{
  params?: InputMaybe<ProjectListParams>;
}>;


export type ProjectSelectionListQuery = { projects: { edges: Array<{ node: { id: string, projectNumber?: number | null, fullName: string, name: string } }>, pageInfo: { limit: number, hasNextPage: boolean, currentPage: number } } };

export type CreateFixedInvoiceProjectDetailsQueryVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type CreateFixedInvoiceProjectDetailsQuery = { project: { id: string, totalInvoicedAmount?: number | null, completionPercentage?: number | null, notInvoicedAmount: number, totalSalesPrice: number } };

export type InvoiceKpiDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InvoiceKpiDataQuery = { invoice: { id: string, contributionRate: number, contributionMarginPerHour: number, costPriceTotal: number, grossSalesPriceTotal: number, hourUsageAmount?: number | null, productUsageAmount?: number | null } };

export type ListProjectsTableQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsTableQuery = { projects: { pageInfo: { limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ node: { id: string, name: string, created: Date } }> } };

export type ListProjectsTestQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsTestQuery = { projects: { pageInfo: { limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ node: { id: string, name: string, created: Date } }> } };

export type ListProjectsQueryVariables = Exact<{
  params: ProjectListParams;
}>;


export type ListProjectsQuery = { projects: { pageInfo: { limit: number, hasNextPage: boolean, hasPrevPage: boolean, currentPage: number }, edges: Array<{ node: { id: string, name: string, created: Date } }> } };

export type EditProjectNameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type EditProjectNameMutation = { projectUpdate: { id: string, projectNumber?: number | null } };

export type ProcurementAnalysisLinesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  linesParams?: InputMaybe<ProcurementAnalysisLineListParams>;
}>;


export type ProcurementAnalysisLinesQuery = { procurementAnalysis: { id: string, procurementAnalysisLines?: Array<{ id: string, name: string, companiesVendorId?: string | null, countOfExpenses: number, countBought: number, totalAmountPaid: number, lastPricePaid: number, averageMarketPrice?: number | null, minimumMarketPrice?: number | null, diffBetweenAverageAndLastPrice: number, optimizationPotential: number, productImageUrls?: { thumbnail: string, original: string } | null }> | null } };

export type ProcurementAnalysisCreateMutationVariables = Exact<{
  input: CreateProcurementAnalysisInput;
}>;


export type ProcurementAnalysisCreateMutation = { procurementAnalysisCreate: { id: string, progress: number } };

export type ProcurementDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProcurementDetailQuery = { procurementAnalysis: { id: string, created: Date, dateStart: Date, dateEnd: Date, status: Procurement_Analysis_Status, progress: number, companiesVendors?: Array<{ id: string, vendorName?: string | null, vendor: { name: string, imageUrl?: string | null } }> | null } };

export type ProcurementIndexProgressCheckQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProcurementIndexProgressCheckQuery = { procurementAnalysis: { id: string, progress: number, status: Procurement_Analysis_Status } };

export type ProcurementIndexQueryVariables = Exact<{
  kpiParams?: InputMaybe<ProcurementKpiCardsParams>;
  analysisParams?: InputMaybe<ProcurementAnalysisListParams>;
}>;


export type ProcurementIndexQuery = { procurementKpiCards: { priceUpdatesCount: number, totalMoneySpent: number, countOfProductsBought: number, averageTotalPricePerExpense: number }, procurementAnalyses: { pageInfo: { hasPrevPage: boolean, hasNextPage: boolean, limit: number, currentPage: number }, edges: Array<{ node: { id: string, created: Date, dateStart: Date, dateEnd: Date, status: Procurement_Analysis_Status, progress: number, companiesVendors?: Array<{ id: string, vendorName?: string | null, vendor: { name: string } }> | null } }> } };

export type VendorSelectionQueryVariables = Exact<{
  params?: InputMaybe<CompaniesVendorListParams>;
}>;


export type VendorSelectionQuery = { companiesVendors: { edges: Array<{ node: { id: string, vendorName?: string | null, vendor: { name: string } } }> } };


export const ProjectSelectionListDocument = gql`
    query projectSelectionList($params: ProjectListParams) {
  projects(params: $params) {
    edges {
      node {
        id
        projectNumber
        fullName
        name
      }
    }
    pageInfo {
      limit
      hasNextPage
      currentPage
    }
  }
}
    `;
export const CreateFixedInvoiceProjectDetailsDocument = gql`
    query createFixedInvoiceProjectDetails($projectId: ID!) {
  project(id: $projectId) {
    id
    totalInvoicedAmount
    completionPercentage
    notInvoicedAmount
    totalSalesPrice
  }
}
    `;
export const InvoiceKpiDataDocument = gql`
    query invoiceKPIData($id: ID!) {
  invoice(id: $id) {
    id
    contributionRate
    contributionMarginPerHour
    costPriceTotal
    grossSalesPriceTotal
    hourUsageAmount
    productUsageAmount
  }
}
    `;
export const ListProjectsTableDocument = gql`
    query listProjectsTable($params: ProjectListParams!) {
  projects(params: $params) {
    pageInfo {
      limit
      hasNextPage
      hasPrevPage
      currentPage
    }
    edges {
      node {
        id
        name
        created
      }
    }
  }
}
    `;
export const ListProjectsTestDocument = gql`
    query listProjectsTest($params: ProjectListParams!) {
  projects(params: $params) {
    pageInfo {
      limit
      hasNextPage
      hasPrevPage
      currentPage
    }
    edges {
      node {
        id
        name
        created
      }
    }
  }
}
    `;
export const ListProjectsDocument = gql`
    query listProjects($params: ProjectListParams!) {
  projects(params: $params) {
    pageInfo {
      limit
      hasNextPage
      hasPrevPage
      currentPage
    }
    edges {
      node {
        id
        name
        created
      }
    }
  }
}
    `;
export const EditProjectNameDocument = gql`
    mutation editProjectName($id: ID!, $name: String!) {
  projectUpdate(id: $id, params: {name: $name}) {
    id
    projectNumber
  }
}
    `;
export const ProcurementAnalysisLinesDocument = gql`
    query procurementAnalysisLines($id: ID!, $linesParams: ProcurementAnalysisLineListParams) {
  procurementAnalysis(id: $id) {
    id
    procurementAnalysisLines(params: $linesParams) {
      id
      name
      companiesVendorId
      countOfExpenses
      countBought
      totalAmountPaid
      lastPricePaid
      averageMarketPrice
      minimumMarketPrice
      diffBetweenAverageAndLastPrice
      optimizationPotential
      productImageUrls {
        thumbnail
        original
      }
    }
  }
}
    `;
export const ProcurementAnalysisCreateDocument = gql`
    mutation procurementAnalysisCreate($input: CreateProcurementAnalysisInput!) {
  procurementAnalysisCreate(input: $input) {
    id
    progress
  }
}
    `;
export const ProcurementDetailDocument = gql`
    query procurementDetail($id: ID!) {
  procurementAnalysis(id: $id) {
    id
    created
    dateStart
    dateEnd
    status
    progress
    companiesVendors {
      id
      vendorName
      vendor {
        name
        imageUrl
      }
    }
  }
}
    `;
export const ProcurementIndexProgressCheckDocument = gql`
    query procurementIndexProgressCheck($id: ID!) {
  procurementAnalysis(id: $id) {
    id
    progress
    status
  }
}
    `;
export const ProcurementIndexDocument = gql`
    query procurementIndex($kpiParams: ProcurementKpiCardsParams, $analysisParams: ProcurementAnalysisListParams) {
  procurementKpiCards(params: $kpiParams) {
    priceUpdatesCount
    totalMoneySpent
    countOfProductsBought
    averageTotalPricePerExpense
  }
  procurementAnalyses(params: $analysisParams) {
    pageInfo {
      hasPrevPage
      hasNextPage
      limit
      currentPage
    }
    edges {
      node {
        id
        created
        dateStart
        dateEnd
        companiesVendors {
          id
          vendorName
          vendor {
            name
          }
        }
        status
        progress
      }
    }
  }
}
    `;
export const VendorSelectionDocument = gql`
    query vendorSelection($params: CompaniesVendorListParams) {
  companiesVendors(params: $params) {
    edges {
      node {
        id
        vendorName
        vendor {
          name
        }
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    projectSelectionList(variables?: ProjectSelectionListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProjectSelectionListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProjectSelectionListQuery>(ProjectSelectionListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'projectSelectionList', 'query', variables);
    },
    createFixedInvoiceProjectDetails(variables: CreateFixedInvoiceProjectDetailsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateFixedInvoiceProjectDetailsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateFixedInvoiceProjectDetailsQuery>(CreateFixedInvoiceProjectDetailsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createFixedInvoiceProjectDetails', 'query', variables);
    },
    invoiceKPIData(variables: InvoiceKpiDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<InvoiceKpiDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<InvoiceKpiDataQuery>(InvoiceKpiDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'invoiceKPIData', 'query', variables);
    },
    listProjectsTable(variables: ListProjectsTableQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ListProjectsTableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListProjectsTableQuery>(ListProjectsTableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listProjectsTable', 'query', variables);
    },
    listProjectsTest(variables: ListProjectsTestQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ListProjectsTestQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListProjectsTestQuery>(ListProjectsTestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listProjectsTest', 'query', variables);
    },
    listProjects(variables: ListProjectsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ListProjectsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListProjectsQuery>(ListProjectsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listProjects', 'query', variables);
    },
    editProjectName(variables: EditProjectNameMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<EditProjectNameMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EditProjectNameMutation>(EditProjectNameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'editProjectName', 'mutation', variables);
    },
    procurementAnalysisLines(variables: ProcurementAnalysisLinesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementAnalysisLinesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementAnalysisLinesQuery>(ProcurementAnalysisLinesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementAnalysisLines', 'query', variables);
    },
    procurementAnalysisCreate(variables: ProcurementAnalysisCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementAnalysisCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementAnalysisCreateMutation>(ProcurementAnalysisCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementAnalysisCreate', 'mutation', variables);
    },
    procurementDetail(variables: ProcurementDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementDetailQuery>(ProcurementDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementDetail', 'query', variables);
    },
    procurementIndexProgressCheck(variables: ProcurementIndexProgressCheckQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementIndexProgressCheckQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementIndexProgressCheckQuery>(ProcurementIndexProgressCheckDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementIndexProgressCheck', 'query', variables);
    },
    procurementIndex(variables?: ProcurementIndexQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProcurementIndexQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProcurementIndexQuery>(ProcurementIndexDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'procurementIndex', 'query', variables);
    },
    vendorSelection(variables?: VendorSelectionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<VendorSelectionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VendorSelectionQuery>(VendorSelectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vendorSelection', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;